import React, { FunctionComponent } from "react";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import classes from "./voorstellen.module.css";
import { extractHtml, extractImage } from "../util";
import Layout from "../components/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import Hero from "../components/hero";
import PageTitle from "../components/page-title";
import HtmlInfoText from "../components/html-info-text";

const query = graphql`
    query {
        introductionText: markdownRemark(fileAbsolutePath: {regex: "/voorstellen.md/"}) {
            html
        }
        image: file(relativePath: { eq: "susan-voorstellen.jpg" }) {
            childImageSharp {
                fixed(width: 250) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`;

const IntroducePage: FunctionComponent<PageProps> = ({ location }) => {
    const data = useStaticQuery(query);
    const { introductionText } = extractHtml(data);
    const { image } = extractImage(data);

    return (
        <Layout currentPath={location.pathname}>
            <SEO title="Voorstellen" description="De introductie van Susan, en een beetje informatie over hoe Praktijk de Leersprong tot stand is gekomen." />
            <Hero />

            <PageTitle>Voorstellen</PageTitle>

            <Container className={classes.container}>
                <Img className={classes.image} fixed={image} alt="Foto van Susan" />
                <HtmlInfoText className={classes.text} html={introductionText} />
            </Container>
        </Layout>
    );
};

export default IntroducePage;
